import { amber, deepOrange, green, grey, red } from "@mui/material/colors";

export const appTheme = {
    color0: "#000",
    color1: "#fff",
    color2: "#d32f2f",
    color3: "#333",
    color4: "#171716",
    color5: "rgba(255, 255, 255, 0.54)",
    color6: `${grey[500]}`,
    color7: `${grey[400]}`,
    color8: `${grey[300]}`,
    color9: `${grey[600]}`,
    color10: `${grey[700]}`,
    color17: `${grey[800]}`,
    color11: `${grey[900]}`,
    color12: `#0555B1`,//`#ea231c`,//`#bc1010`,
    color13: `${green[500]}`,
    color14: `${red[500]}`,
    color15: `${red[800]}`,
    color16: `${deepOrange[800]}`,
    color18: `${amber[800]}`,
    color19: `#20C2AE`,
    colorGradient:[
        {
            blueLight: '#0f1825',
            blueDark: '#174b89'
        },
        {
            greenLight: '#141d18',
            greenDark: '#3a6947'
        },
        {
            redLight: '#241314',
            redDark: '#923233'
        },
        {
            purpleLight: '#1d0e21',
            purpleDark: '#6a1781'
        }
    ]
}